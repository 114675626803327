.heroBanner {
    width: 100%;
    grid-row: 1/3;
    grid-column: 1/4;
    z-index: 1;
    display: grid;
    grid-template: 113px 1fr / clamp(20px, 120px, 200px) 1fr clamp(20px, 120px, 200px);
    background: linear-gradient(75.92deg, rgba(0, 27, 78, 0.8) 39.41%, rgba(0, 27, 78, 0.56) 100.22%);
}

.heroBanner picture {
    grid-row: 1/3;
    grid-column: 1/4;
    z-index: 1;
    max-width: 100%;
    overflow: hidden;
}

.heroBanner .heroBannerCTABlock {
    grid-row: 2/3;
    grid-column: 2/3;
    z-index: 3;
    margin-top: 133px;
    max-height: 500px;
}

.heroBanner .heroBannerCTABlock span {
    color: rgba(52, 165, 219, 1);
    font-size: 1.33rem;
    line-height: 1.61rem;
    font-weight: 400;
    margin: 0;
}

.heroBanner .heroBannerCTABlock h1 {
    font-size: 3.55rem;
    line-height: 4.27rem;
    margin: 10px 0 20px 0;
}

@media only screen and (max-width: 1210px) {
    .heroBanner .heroBannerCTABlock {
        margin-top: 110px;
        margin-bottom: 110px;
    }
}

@media only screen and (max-width: 1024px) {
    .heroBanner {
        grid-template: 113px 1fr / clamp(20px, 40px, 200px) 1fr clamp(20px, 40px, 200px);
    }
}

@media only screen and (max-width: 750px) {
    .heroBanner {
        grid-template: 113px 1fr / 20px 1fr 20px;
    }
    
}

@media only screen and (max-width: 645px) {
    .heroBanner .heroBannerCTABlock {
        padding: 0 15px;
    }
}