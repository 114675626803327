html, body {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
}

#wrapper {
    display: grid;
    grid-template: 1fr 1fr / 1fr;
}

.margin18Right {
    margin-right: 18px;
}

.imgResponsive {
    height: 100%;
    width: auto;
    display: inline-block;
}

.displayFlex {
    display: flex!important;
}

@media only screen and (max-width: 1210px) {
    html, body {
        font-size: 16px
    }
}

@media only screen and (max-width: 1024px) {
    html, body {
        font-size: 14px;
    }
}

@media only screen and (max-width: 880px) {
    html, body {
        font-size: 12px;
    }
}