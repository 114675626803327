.main {
    display: grid;
    grid-template: 1fr / clamp(20px, 120px, 200px) 1fr clamp(20px, 120px, 200px);
    justify-items: center;
    height: fit-content;
}

.firstSection {
    position: relative;
    top: -34px;
    z-index: 4;
    max-width: 100%;
    grid-row: 1/2;
    grid-column: 2/3;
}

.firstSectionImgContainer {
    border-radius: 8px 0 0 8px;
    overflow: hidden;
    max-height: 437px;
    background-color: rgba(19, 129, 147, 1);
}

.firstSectionTextContainer {
    padding: 83px 61px;
    background-color: rgba(19, 129, 147, 1);
    border-radius: 0 8px 8px 0;
    max-height: 437px;
    box-sizing: border-box;
}

.firstSectionTextContainer span {
    color: rgba(187, 220, 236, 1);
    font-weight: 400;
    font-size: 1.11rem;
    line-height: 1.33rem;
}

.firstSectionTextContainer h2 {
    font-weight: 700;
    font-size: 1.77rem;
    line-height: 2.11rem;
    margin: 1.16rem 0 1.22rem 0;
}

.firstSectionTextContainer p {
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 1.77rem;
}

.squareCorner {
    width: 208px;
    height: 154px;
    background: #F26440;
    border-radius: 0px 0px 0px 8px;
    position: relative;
    display: inline-block;
    top: -133px;
    z-index: -1;
    left: -20px;
}

@media only screen and (max-width: 1210px) {
    
    .firstSectionTextContainer {
        padding: 51px 69.5px;
    }
    .firstSectionImgContainer, .firstSectionTextContainer {
        max-height: 366px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1210px) {
    .main .section .col-2 {
        grid-template: 1fr / 1fr 1.5fr;
    }
}

@media only screen and (max-width: 1024px) {
    .main {
        grid-template: 1fr / clamp(20px, 40px, 200px) 1fr clamp(20px, 40px, 200px);
    }
    .firstSectionImgContainer, .firstSectionTextContainer {
        max-height: 311px;
    }
    .firstSectionTextContainer {
        padding: 43px 59px;
    }
}

@media only screen and (max-width: 880px) {
    .main {
        grid-template: clamp(20px, 120px, 200px) 1fr clamp(20px, 120px, 200px);
    }
    
    .firstSectionImgContainer, .firstSectionTextContainer {
        max-height: 267px;
    }
    .firstSectionTextContainer {
        padding: 37px 50px;
    }
}

@media only screen and (max-width: 750px) {
    .main {
        grid-template: 1fr / 20px 1fr 20px;
    }
    .firstSectionImgContainer, .firstSectionTextContainer {
        max-height: 250px;
    }
    .firstSectionTextContainer {
        padding: 25px 25px;
    }
}

@media only screen and (max-width: 645px) {
    .firstSectionTextContainer {
        padding: 25px;
        border-radius: 0 0 8px 8px;
        max-height: none;
    }

    .firstSectionTextContainer p {
        font-size: 1rem;
    }
    .firstSectionImgContainer img {
        width: 100%;
        height: auto;
    }
    .firstSectionImgContainer {
        border-radius: 8px 8px 0 0;
        max-height: 228px;
    }
    .squareCorner {
        top: -143px;
        left: -10px;
    }
}