.header {
    display: grid;
    grid-template: 1fr / clamp(20px, 120px, 200px) 1fr clamp(20px, 120px, 200px);
    height: fit-content;
}

@media only screen and (max-width: 1024px) {
    .header {
        grid-template: 1fr / clamp(20px, 40px, 200px) 1fr clamp(20px, 40px, 200px);
    }
}

@media only screen and (max-width: 750px) {
    .header {
        grid-template: 113px 1fr / 20px 1fr 20px;
    }
}