.navigationMenu {
    background-color: #fff;
    height: 87px;
    max-width: 1180px;
    width: 100%;
    grid-row: 1 / 2;
    grid-column: 2/3;
    z-index: 10;
    border-radius: 8px;
    margin-top: 26px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    display: grid;
    grid-template: 1fr / 118px minmax(0px, 195px) clamp(200px, 554px, 554px) minmax(0px, 313px);
    align-items: center;
    overflow: hidden;
}

.navigationMenu img.logo {
    margin: 19px 0 19px 23px;
}

.navigationMenu ul {
    list-style: none;
    gap: 48px;
    padding: 0;
    margin: 0 20px;
    display: grid;
    grid-template: 1fr / repeat(6,1fr);
}

.navigationMenu ul li a {
    color: rgba(0, 27, 78, 0);
    text-decoration: none;
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 19.2px;
    transition: all 200ms ease-out;
    display: inline-block;
    height: 19.2px;
}

.navigationMenu ul li a:hover {
    color: rgba(242, 100, 64, 1);
}

.navigationMenu ul li a:hover::before {
    transform: scale(0.9);
    color: rgba(0, 27, 78, 0);
}

.navigationMenu ul li a::before {
    color: rgba(0, 27, 78, 1);
    content: attr(data-hover);
    position: absolute;
    transition: all 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 19.2px;
    box-sizing: border-box;
}

.navigationMenu .icon {
    width: 30px;
    height: 30px;
    flex-direction: column;
    justify-content: space-between;
    padding: 28.5px;
    border-radius: 0 8px 8px 0;
    background-color: beige;
    position: relative;
    top: -0.5px;
    display: none;
}

.navigationMenu .icon:hover {
    cursor: pointer;
}

.navigationMenu .icon div {
    background-color: black;
    height: 5px;
    width: 100%;
    border-radius: 5px;
}

.barOne, .barTwo, .barThree {
    transition: all 200ms ease-in-out;
}

.navigationMenu .icon.open div.barOne {
    transform: rotate(45deg) translate(9px, 9px)
}

.navigationMenu .icon.open div.barTwo {
    transform: translateX(20px);
    opacity: 0;
}

.navigationMenu .icon.open div.barThree {
    transform: rotate(-45deg) translate(9px, -9px);
}

.mobileNav ul li {
    padding: 10px 25px;
    text-align: center;
}

@media only screen and (max-width: 1210px) {
    /* .navigationMenu {
        max-width: 987px;
    } */
    .navigationMenu ul li a {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) {
    .navigationMenu ul li a {
        font-size: 16px
    }
    .navigationMenu ul li a::before {
        font-size: 16px;
    }
    .navigationMenu ul {
        gap: 20px;
    }
    .navigationMenu .spacer {
        display: none;
    }
    .navigationMenu {
        grid-template: 1fr / 118px 1fr;
    }
}

@media only screen and (max-width: 750px) {
    .navigationMenu ul {
        grid-template: 1fr / repeat(6,auto);
    }
}

@media only screen and (max-width: 645px) {
    .navigationMenu {
        justify-items: end;
    }

    .navigationMenu ul {
        display: flex;
        gap: 10px;
        flex-direction: column;
        position: absolute;
        background-color: beige;
        border-radius: 8px;
        align-items: center;
        padding: 10px 0;
        top: 114px;
        opacity: 1;
        right: 0px;
        width: calc(100% - 40px);
        margin: 0 20px;
        max-height: 0px;
        transition: all 500ms ease-in-out;
        visibility: hidden;
    }

    .navigationMenu .open + ul {
        max-height: 500px;
        visibility: visible;
    }

    .navigationMenu ul li {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 150ms ease-in-out;
    }

    .navigationMenu ul li:active {
        opacity: 1;
        background-color: white;
        border: 1px solid black;
        transform: scale(1.05);
        border-radius: 8px;
    }

    .navigationMenu ul li a {
        opacity: 0;
        transition: opacity 150ms ease-in 150ms;
    }

    .navigationMenu .open + ul li a {
        opacity: 1;
    }


    .mobileNav {
        display: flex;
    }
    
    .navigationMenu .icon {
        display: flex;
    }
}