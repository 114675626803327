button {
    box-sizing: border-box;
    width: 164px;
    height: 54px;
    color: #fff;
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 19.2px;
    border-radius: 8px;
    font-family: 'Roboto';
    transition: all 200ms ease-out;
}
button:hover {
    cursor: pointer;
}

button.primary {
    background-color: rgba(242, 100, 64, 1);
    border: 2px solid rgba(242, 100, 64, 1);
}

button:active {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    width: 150px;
    height: 50px;
    margin-right: 7px;
    margin-left: 7px;
    font-size: .8rem;
}

button.secondary {
    background-color: #ffffff00;
    border: 2px solid #fff;
}

.heroBannerCTABlock button.primary:active {
    margin-right: 25px;
}

@media only screen and (max-width: 1024px) {
    button {
        font-size: 14px
    }
}

@media only screen and (max-width: 430px) {
    button {
        width: 130px;
        height: 48px;
    }

    .heroBannerCTABlock button:active {
        width: 117px;
        height: 44px;
        margin-right: 6.5px!important;
        margin-left: 6.5px!important;
        font-size: .88rem;
    }
    .heroBannerCTABlock button.primary:active {
        margin-right: 24.5px!important;
    }
}

@media only screen and (max-width: 350px) {
    .heroBannerCTABlock button {
        width: 120px;
    }

    .heroBannerCTABlock button:active {
        width: 108px;
        margin-right: 6.5px;
        margin-left: 6.5px;
    }

    .heroBannerCTABlock button.primary:active {
        margin-right: 23.5px!important;
    }
}